import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from "gsap/CustomEase";

function twkScroll() {
  document.addEventListener('DOMContentLoaded', function () {
    //console.log('DOMContentLoaded');
    // wait until images, links, fonts, stylesheets, and js is loaded
    window.addEventListener("load", function(e) {
      //console.log('load');
      // Scroll animations system with GSAP
      let sections = gsap.utils.toArray('[twk-aos]');
      let onFirstBlock = false;

      sections.forEach((section) => {
        // Check if the section is the first block on the main container.
        // We want it to animate earlier than the others.
        if ( section.closest('section') !== null ){
          const parent         = document.querySelector('main.main .col-12');
          const firstSectionId = parent.firstElementChild.id

          if ( section.closest('section').id === firstSectionId ) {
            onFirstBlock = true;
          } else {
            onFirstBlock = false;
          }
        }

        // Defaults
        let twkAosTrigger  = ( onFirstBlock ? 'top bottom-=20px' : 'top bottom-=150px' );
        let twkAosDuration = 0.8;
        let twkAosDelay    = '<';
        let twkAosStagger  = 0.2;
        let twkAosMarker   = false;
        let twkAosMarkerId = 'section';

        if ( section.getAttribute('twk-aos-trigger') !== null ){
          twkAosTrigger = section.getAttribute('twk-aos-trigger');
        }
        if ( section.getAttribute('twk-aos-duration') !== null ){
          twkAosDuration = section.getAttribute('twk-aos-duration') / 1000;            // From html we get them in milliseconds
        }
        if ( section.getAttribute('twk-aos-delay') !== null ){
          twkAosDelay = section.getAttribute('twk-aos-delay') / 1000;                  // From html we get them in milliseconds
        }
        if ( section.getAttribute('twk-aos-stagger') !== null ){
          // Check if result is NaN - which will mean we didn't set a value
          // and so we want the default.
          if ( ! Number.isNaN(parseInt(section.getAttribute('twk-aos-stagger'))) ){
            twkAosStagger = section.getAttribute('twk-aos-stagger') / 1000;          // From html we get them in milliseconds
          }
        }
        if ( section.getAttribute('twk-aos-marker') !== null ){
          twkAosMarker  = true;
          twkAosMarkerId = section.getAttribute('twk-aos-marker');
        }


        /* 
        // Log data.
        console.table({
          'twk-aos-trigger' : { 'Value': twkAosTrigger },
          'twk-aos-duration' : {'Value': twkAosDuration },
          'twk-aos-delay' : {'Value': twkAosDelay },
          'twk-aos-marker' : {'Value': twkAosMarker },
          'HTML' : {'Value': section }
        });
        */


        let tl = gsap.timeline({
          defaults: {
            duration: twkAosDuration,
            //ease: "power1.out"
            ease: CustomEase.create("customEase", "0.215, 0.61, 0.355, 1")
          },
          scrollTrigger: {
            trigger: section,
            fastScrollEnd: true,
            once: true,
            start: twkAosTrigger,
            end: "bottom 80%", // fastScrollEnd triggers as we leave the section so make sure you have an end position set low down enough to see the impact.
            id: twkAosMarkerId,
            markers: twkAosMarker,
            toggleClass: 'twk-aos-animating',
            onEnter: () => section.classList.add('twk-aos-animate'),
            onLeave: () => section.classList.add('twk-aos-animated'),
          }
        });

        /**
         * Scroll animations
         * 
         * Options:
         * fade, fade-up, fade-down, fade-left, fade-right
         * slide-up, slide-down, slide-left, slide-right
         * zoom-in, zoom-out
         */
        let animationObject;
        let animationToObject;
        let animationAttr = section.getAttribute('twk-aos');

        // Create the GSAP object for the animation depending on the twk-aos attribute.
        switch ( animationAttr ) {
          case 'fade':
            animationObject = { opacity: 0 }
            animationToObject = { opacity: 1 }
            break;
          case 'fade-up':
            animationObject = { opacity: 0, y: '100px' }
            animationToObject = { opacity: 1, y: '0px' }
            break;
          case 'fade-down':
            animationObject = { opacity: 0, y: '-100px' }
            animationToObject = { opacity: 1, y: '0px' }
            break;
          case 'fade-left':
            animationObject = { opacity: 0, x: '100px' }
            animationToObject = { opacity: 1, x: '0px' }
            break;
          case 'fade-right':
            animationObject = { opacity: 0, x: '-100px' }
            animationToObject = { opacity: 1, x: '0px' }
            break;
          case 'slide-up':
            animationObject = { y: '100px' }
            animationToObject = { y: '0' }
            break;
          case 'slide-down':
            animationObject = { y: '-100px' }
            animationToObject = { y: '0' }
            break;
          case 'slide-left':
            animationObject = { x: '100px' }
            animationToObject = { x: '0' }
            break;
          case 'slide-right':
            animationObject = { x: '-100px' }
            animationToObject = { x: '0' }
            break;
          case 'zoom-in':
            animationObject = { scale: 0.9 }
            animationToObject = { scale: 1 }
            break;
          case 'zoom-out':
            animationObject = { scale: 1.1 }
            animationToObject = { scale: 1 }
            break;
          case 'fade-jigsaw':
            animationObject = { opacity: 0 }
            animationToObject = { opacity: 0.1 }
            break;
        
          default:
            animationObject = { opacity: 0 }
            animationToObject = { opacity: 1 }
            break;
        }
        

        //TODO: make the scrollTrigger work with the stagger. Or make differece between stagger archive and single row.
        if ( section.getAttribute('twk-aos-stagger') !== null ){
          tl.to( section, { opacity: 1, x:0, y: 0, scale: 1 } ) // makes visible the parent div

          // animation on the children
          section.querySelectorAll(":scope > *").forEach(function(block, index) {
            const selector = '[twk-aos-stagger] > .' + gsap.utils.toArray(block)[0].classList[0] + ':nth-child('+(index+1)+')';

            //console.log(selector);
            tl = gsap.timeline({
              scrollTrigger: {
                trigger: selector,
              }
            });

            tl.fromTo(selector, animationObject, animationToObject, twkAosDelay);
          });
        } else {
          //console.log('Animating...');
          //console.log(section);
          //console.log(animationObject);
          //console.log(animationToObject);
          //console.log(twkAosDelay);
          tl.fromTo( section, animationObject, animationToObject, twkAosDelay )
        }
      });

    }, false);
  });

}
export default twkScroll;