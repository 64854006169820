import twkScroll from './animations/twkScrollAnimations';
import VideoCursor from './animations/VideoCursor';
import Home from './animations/Home';
import Landing from './animations/Landing';
import Admissions from './animations/Admissions';
import OnScroll from './animations/OnScroll';
import { $qs, $qsa } from './utils/QuerySelector';


// Vanilla JS
function init() {
  twkScroll();
	if ($qs('body.page-template-tpl-home')) Home();
  if ($qs('body.page-template-tpl-landing')) Landing();
  if ($qs('body.page-template-tpl-admissions')) Admissions();
  if ($qs('body.page-template-tpl-home')) VideoCursor();
  OnScroll();
}
init();




jQuery(document).ready(function ($) {
  consoleMessage: {
    console.log('%cCreated by %cTWK%cwww.thewebkitchen.co.uk', 'background: #13212E; color: #FFFFFF; padding: 5px 0px 5px 10px; margin: 25px 0px;', 'background: #13212E; color: #05E5C8; padding: 5px 10px 5px 0px; font-weight: bold;', 'background: #FFFFFF; padding: 5px 10px;');
  }

  modernizer: {
    if (!Modernizr.objectfit) {
      $('.object-fit').each(function () {
      var $container = $(this),
        imgUrl = $container.find('img').prop('src');
      if (imgUrl) {
        $container
        .css('backgroundImage', 'url(' + imgUrl + ')')
        .addClass('not-compatible');
      }
      });
    }
  }

  fixedheader: {
    $(function () {
      $(window).scroll(function () {
      if ($(window).scrollTop() >= 150) {
        $('.header').addClass('fixed-header');
      }
      if ($(window).scrollTop() >= 160) {
        $('.header').addClass("transform-none");
      }
      else {
        $('.header').removeClass('fixed-header');
        $('.header').removeClass("transform-none");
      }
      });
    });
  }

  search: {
    $('.search-icon').on('click', function (e) {
      $('.searchform').toggleClass('search-open');
      $(this).toggleClass('submit-zindex');
      $('.select-lang').removeClass('transform-height');
      $('#menu-main-menu-top, .lang_dropdown').fadeOut();
      $("#s").focus();
      setTimeout(function () {
      $('.close-search').fadeIn();
      }, 300);
    });
    $('.close-search').on('click', function (e) {
      $(this).fadeOut();
      $('.searchform').removeClass('search-open');
      $('.search-icon').removeClass('submit-zindex');
      $('#menu-main-menu-top, .lang_dropdown').fadeIn();
    });
  }

  menu: {

    // Open menu on click
    $( '.top-nav .js-menu-button' ).on('click', function(e){
      $(this).toggleClass('is-active');
      $('.menu-wrapper .menu-part').toggleClass('is-active');
      $('.top-nav').toggleClass('menu-is-active');
      //$('body').toggleClass('no-scroll');
      if ( $(this).hasClass('is-active') ) {
        $(this).find('.text').text('close');
      } else {
        $(this).find('.text').text('menu');
      }
    });


    // Accordion menu
    function accordionMenu(menuClass) {
      var menuName = menuClass; 
      if ( $(menuName).find('.menu > li').hasClass('current_page_ancestor') ) {
        $(menuName).find('.menu > li.current_page_ancestor').children('.sub-menu').slideDown().addClass('is-active');
        $(menuName).find('.menu > li.current_page_ancestor').addClass('is-active');
      }
      $(menuName).find('.menu > .menu-item-has-children > a').on('click', function(e){
        e.preventDefault();
        if ( $(this).next().hasClass('is-active') ) {
          $(menuName).find('.sub-menu').removeClass('is-active');
          $(menuName).find('.menu > li').removeClass('is-active');
          $(menuName).find('.sub-menu').slideUp();
        } else {
          $(menuName).find('.sub-menu').removeClass('is-active');
          $(menuName).find('.menu > li').removeClass('is-active');
          $(menuName).find('.sub-menu').slideUp();
          $(this).next().addClass('is-active');
          $(this).parent().addClass('is-active');
          $(this).next().slideDown();
        }
      });
    }

    // Secondary menu accordion
    accordionMenu('.menu-secondary-menu-container');


    // Turn regular menus into accordions on smaller screen sizes
    if ( window.matchMedia('(max-height: 700px)').matches || window.matchMedia('(max-width: 991px)').matches ) { 
      accordionMenu('.menu-main-menu-left-container');
      accordionMenu('.menu-main-menu-right-container');
    }



    // Open submenu on click
    $( '.top-nav .menu-item-has-children > a' ).on('click', function(e){
      e.preventDefault();

      $('.menu-item-has-children').removeClass('sub-menu-open'); // reset
      $('.sub-menu-wrap').removeClass('sub-menu-open');          // reset

      $(this).closest('.menu-item-has-children').toggleClass('sub-menu-open');
      $(this).next('.sub-menu-wrap').toggleClass('sub-menu-open');
    });
    // Open menu with tabs - accessibility
    $( '.top-nav .sub-menu-wrap a' ).on('focusin', function(){
      $(this).closest('.menu-item-has-children').addClass('sub-menu-open');
      $(this).closest('.sub-menu-wrap').addClass('sub-menu-open');

      console.log($(this));
    });
    $( '.top-nav .sub-menu-wrap a' ).on('focusout', function(){
      $(this).closest('.menu-item-has-children').removeClass('sub-menu-open');
      $(this).closest('.sub-menu-wrap').removeClass('sub-menu-open');
    });
    // Close on click outside.
    $(document).on('click', function(e){
      if ( ! $(e.target).parent().hasClass('menu-item') && $('.sub-menu-wrap').has(e.target).length === 0 ){
      $('.menu-item-has-children').removeClass('sub-menu-open');
      $('.sub-menu-wrap').removeClass('sub-menu-open');
      }
    });
  }




  anchorlinks: {
    $(document).on('click', '.scroll-to', function (event) {
      event.preventDefault();

      $('html, body').animate({
      scrollTop: $($.attr(this, 'href')).offset().top
      }, 500);
    });

    $('.js-scroll-page').on('click', function(e) {
      e.preventDefault(); 
      $('html, body').animate({
        scrollTop: '+=650px'
      }, 500);
    })
  }

  externallinks: {
    $('a[href^="mailto:"]').each(function () {
      $(this).addClass('email-link');
    });	 
    
    $('a:not(.email-link):not([class^="magnific-"])').each(function () {
      var a = new RegExp('/' + window.location.host + '/');
      
      if (!a.test(this.href)) {
      $(this).click(function (event) {
      event.preventDefault();
      window.open(this.href, '_blank');
      });
      }
    });
    pdfs: {
      // Open PDFs in new window
      $(function () {
      $('a[href$=".pdf"]').prop('target', '_blank');
      });
    }
  }

  slider: {
    $('.full-width-slider').slick({
      dots: true,
      arrows: false,
      fade: true,
      autoplay: true,
      autoplaySpeed: 3500,
      speed: 1200,
    });

    $('.basic-slider').slick({
      dots: true,
      arrows: false,
      fade: true,
      autoplay: false,
      autoplaySpeed: 3500,
      speed: 1200,
    });
    

    $(".image-slider").each(function () {
      var slider = $(this);
  
      slider.slick({
        dots: true,
        arrows: true,
        fade: true,
        autoplay: false,
        autoplaySpeed: 3500,
        speed: 1200,
        nextArrow: slider.parent().find('.image-slider-next'),
        prevArrow: slider.parent().find('.image-slider-prev'),          
      });
    });


    $(".quote-slider").each(function () {
      var slider = $(this);
  
      slider.slick({
        dots: true,
        arrows: true,
        fade: true,
        autoplay: false,
        autoplaySpeed: 3500,
        speed: 1200,
        nextArrow: slider.parent().find('.quote-slider-next'),
        prevArrow: slider.parent().find('.quote-slider-prev'),          
      });
    });

    $('.silcoates-slider__images').slick({
      dots: false,
      arrows: false,
      fade: true,
      autoplay: true,
      autoplaySpeed: 3500,
      speed: 1200,
      asNavFor: '.silcoates-slider__text',
    });
    $('.silcoates-slider__text').slick({
      dots: false,
      arrows: false,
      fade: true,
      autoplay: false,
      autoplaySpeed: 3500,
      speed: 1200,
      asNavFor: '.silcoates-slider__images',
    });


    $('.opportunity-slider__images').slick({
      dots: false,
      arrows: true,
      fade: true,
      autoplay: false,
      autoplaySpeed: 3500,
      speed: 1200,
      nextArrow: $('.opportunity-slider-next'),
      prevArrow: $('.opportunity-slider-prev'),
      asNavFor: '.opportunity-slider__stats',
    });
    $('.opportunity-slider__stats').slick({
      dots: false,
      arrows: false,
      fade: true,
      autoplay: false,
      autoplaySpeed: 3500,
      speed: 1200,
      asNavFor: '.opportunity-slider__images',
    });


    var puzzleSlider = $('.puzzle-slider')
    puzzleSlider.slick({
      dots: false,
      arrows: true,
      fade: true,
      autoplay: false,
      autoplaySpeed: 3500,
      speed: 1200,
    });
    // On slide change, update puzzle pieces
    puzzleSlider.on('beforeChange', function(event, slick, currentSlide, nextSlide){
      console.log(nextSlide);
      $('.puzzle-svg image').removeClass('active');
      if ( nextSlide == 0 ) {
        $('.puzzle-svg #support').addClass('active');
      } else if ( nextSlide == 1) {
        $('.puzzle-svg #shines').addClass('active');
      } else if ( nextSlide == 2) {
        $('.puzzle-svg #succeeds').addClass('active');
      } else if ( nextSlide == 3) {
        $('.puzzle-svg #spirit').addClass('active');
      }
    });
        

    // Clicking on puzzle pieces
    $('.puzzle-svg #support').on('click', function() {
      puzzleSlider.slick('slickGoTo', 0);
      $('.puzzle-svg image').removeClass('active');
      $(this).addClass('active');
    });
    $('.puzzle-svg #shines').on('click', function() {
      puzzleSlider.slick('slickGoTo', 1);
      $('.puzzle-svg image').removeClass('active');
      $(this).addClass('active');
    });
    $('.puzzle-svg #succeeds').on('click', function() {
      puzzleSlider.slick('slickGoTo', 2);
      $('.puzzle-svg image').removeClass('active');
      $(this).addClass('active');
    });
    $('.puzzle-svg #spirit').on('click', function() {
      puzzleSlider.slick('slickGoTo', 3);
      $('.puzzle-svg image').removeClass('active');
      $(this).addClass('active');
    });

    $('.stats-slider').flickity({
      autoPlay: false,
      prevNextButtons: false,
      pageDots: false,
      draggable: true,
      wrapAround: true,
      freeScroll: true,
    });
    $('.stats-slider-prev').on( 'click', function() {
      $('.stats-slider').flickity('previous', true);
    });
    $('.stats-slider-next').on( 'click', function() {
      $('.stats-slider').flickity('next', true);
    });

    
    var count = $('.vacancy-item').length;

    if ( count >= 5 ) {
      $('.vacancy-slider').flickityResponsive({
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: true,
        freeScroll: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              freeScroll: false,
              cellAlign: 'center'
            }
          }
        ]
      });
    } else {
      $('.vacancy-slider').flickityResponsive({
        autoPlay: false,
        prevNextButtons: false,
        pageDots: false,
        draggable: true,
        wrapAround: false,
        freeScroll: true,
        cellAlign: "center",
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              draggable: true,
              freeScroll: false,
              wrapAround: true,
              cellAlign: 'center'
            }
          }
        ]
      });
    }
    



    /**
     * Gallery slider with thumbnails navigation.
     */
    $( '.gallery-slider' ).each(function(index){
      var status            = $(this).find('.gallery-slider__full-nav .pages');
      var slickElement      = $(this).find('.gallery-slider__full');
      var slickElementThumb = $(this).find('.gallery-slider__thumb');

      slickElement.on('init reInit afterChange', function (event, slick, currentSlide, nextSlide) {
      //currentSlide is undefined on init -- set it to 0 in this case (currentSlide is 0 based)
      var i = (currentSlide ? currentSlide : 0) + 1;
      status.text(i + ' of ' + slick.slideCount);
      });

      slickElement.slick({
      dots: false,
      arrows: true,
      fade: true,
      asNavFor: $(this).find('.gallery-slider__thumb'),
      appendArrows: $(this).find('.gallery-slider__full-nav'),
      });

      slickElementThumb.slick({
      dots: false,
      arrows: true,
      slidesToShow: 4,
      // centerMode: true,
      focusOnSelect: true,
      asNavFor: $(this).find('.gallery-slider__full'),
      });
    });
  }
  
  magnific: {
    // IFRAME
    $('.magnific-video').magnificPopup({
      type: 'iframe',
      mainClass: 'mfp-video-wrapper',
      removalDelay: 160,
      preloader: false,
      fixedContentPos: false,

      iframe: {
      markup: '<div class="mfp-iframe-scaler">' +
        '<div class="mfp-close"></div>' +
        '<iframe class="mfp-iframe" frameborder="0" allow="autoplay"></iframe>' +
        '</div>',
      patterns: {
        youtu: {
        index: 'youtu.be',
        id: function( url ) {
        
          // Capture everything after the hostname, excluding possible querystrings.
          var m = url.match( /^.+youtu.be\/([^?]+)/ );
        
          if ( null !== m ) {
          return m[1];
          }
        
          return null;
      
        },
        // Use the captured video ID in an embed URL. 
        // Add/remove querystrings as desired.
        src: '//www.youtube.com/embed/%id%?autoplay=1&rel=0'
        },
        youtube: {
        index: 'youtube.com/',
        id: 'v=',
        src: 'https://www.youtube.com/embed/%id%?autoplay=1'
        }
      }
      }
    });


    // INLINE
    $('.magnific-inline-popup').magnificPopup({
      type: 'inline',

      fixedContentPos: true,
      fixedBgPos: true,

      overflowY: 'auto',

      closeBtnInside: true,
      preloader: false,
      
      midClick: true,
      removalDelay: 300,
      mainClass: 'my-mfp-zoom-in'
    });


    // GALLERY SINGLE IMAGE
    $('.magnific-gallery-single-image').magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      mainClass: 'mfp-img-single',
      image: {
      verticalFit: true,
      titleSrc: function titleSrc(item) {
      return item.el.attr('title');
      }
      },
      gallery: {
      enabled: false
      }
    });          

    // GALLERY IMAGE
    $('.magnific-gallery-image').magnificPopup({
      type: 'image',
      closeOnContentClick: true,
      mainClass: 'mfp-img-mobile',
      image: {
      verticalFit: true,
      titleSrc: function(item) {
        return item.el.attr('title');
      }
      },
      gallery:{
      enabled:true
      }
    });
  }

  accordion: {
    if ($('.block--accordion').length) {

      $( '.accordion__content--wrapper' ).css('display', 'none'); // All items closed.
    
      $('.block--accordion').find('.js-accordion-trigger').on('click', function() {

      // Close only the items in this accordion.
      $(this).parent().siblings().find('.js-accordion-trigger').next().slideUp('fast'); // reset
      $(this).parent().siblings().find('.js-accordion-trigger').removeClass('open');    // reset

      // Close items in all accordions on the page.
      //$('.js-accordion-trigger').not(this).next().slideUp('fast'); // reset
      //$('.js-accordion-trigger').not(this).removeClass('open');    // reset
      
      if ($(this).hasClass('open')){
        $(this).next().slideUp('fast');
        $(this).removeClass('open');
      } else {
        $(this).next().slideDown('fast');
        $(this).addClass('open');
      }
      
      });
    }
  }

  tables: {
    $('table').wrap('<div class="table-wrapper"></div>');
  }



  scrollTabs: {
    var winHeight = window.innerHeight;
    var scrollOffsetTop = winHeight * 0.5;
    var scrollOffsetTopNew = winHeight * 0.3;

    $(document).on('click', '.scroll-to-admissions', function (event) {
      event.preventDefault();

      $('html, body').animate({
        scrollTop: $($.attr(this, 'href')).offset().top - Math.round(scrollOffsetTopNew)
      }, 500);
    });

    $(window).scroll(function () {
      var scrollTop = $(document).scrollTop();
      var anchors = $('body').find('.step');
      var panelOne = 'panel-1-1';
      if ( $('.section-1') ) {
        panelOne = 'panel-1-1';
        changeClassesOnScroll();
      } else if ( $('.section-2') ) {
        panelOne = 'panel-2-1';
        changeClassesOnScroll();
      } else if ( $('.section-3') ) {
        panelOne = 'panel-3-1';
        changeClassesOnScroll();
      } else if ( $('.section-4') ) {
        panelOne = 'panel-4-1';
        changeClassesOnScroll();
      }

      function changeClassesOnScroll() {
        for (var i = 0; i < anchors.length; i++) {
          if (scrollTop >= $(anchors[i]).offset().top - Math.round(scrollOffsetTop) && scrollTop <= $(anchors[i]).offset().top + $(anchors[i]).height() - Math.round(scrollOffsetTopNew)) {
            $('.step-link[href="#' + $(anchors[i]).attr('id') + '"]').parent().addClass('current_page_item');
            $('#' + $(anchors[i]).attr('id')).addClass('active');
            $('#' + $(anchors[i]).attr('id')).find('.step__dot').addClass('show');
          } else if ( scrollTop < $('#' + panelOne).offset().top ) {
            $('.step-link[href="#' + panelOne).parent().addClass('current_page_item');
            $('#' + panelOne).addClass('active');
            $('#' + panelOne).find('.step__dot').addClass('show');
          } else {
            $('.step-link[href="#' + $(anchors[i]).attr('id') + '"]').parent().removeClass('current_page_item');
            $('#' + $(anchors[i]).attr('id')).removeClass('active');
            $('#' + $(anchors[i]).attr('id')).find('.step__dot').removeClass('show');
          }
        }
      }
  
    });
  }

  loadingAnimations: {
    $.fn.isOnScreen = function () {
      var win = $(window);
      var viewport = {
      top: win.scrollTop()
      };
      viewport.bottom = viewport.top + win.height() - 80;
    
      var bounds = this.offset();
      bounds.bottom = bounds.top + this.outerHeight();
    
      return (!(viewport.bottom < bounds.top || viewport.top > bounds.bottom));
    };

    // First load Animation
    $('.off-screen').each(function (index) {
      if ($(this).isOnScreen()) {
      $(this).removeClass('off-screen--hide');
      }
    });

    // Animation on scroll
    $(window).scroll(function () {
      $('.off-screen').each(function (index) {
      if ($(this).isOnScreen()) {
        $(this).removeClass('off-screen--hide');
      }
      });
    });
  }
});


