import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from "gsap/CustomEase";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { $qs, $qsa } from '../utils/QuerySelector';
import { media } from '../utils/MediaQueries';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(DrawSVGPlugin);




// Set width, height, position left and bottom of svg lines
function positionSVGLinesLanding() {
  const svgOne = $qs('.section-1 .svg-line');

  const positionOne = $qs('.section-1 .button');
  const positionTwo = $qs('.section-2 .scroll-down');

  let leftOne = positionOne.offsetLeft + 120;
  let widthOne = positionTwo.offsetLeft - leftOne + window.innerWidth;
  let heightOne = widthOne / 3.387;
  let bottomOne = positionOne.offsetTop - ( heightOne/2 ) + 80;

  // Svg 1
  svgOne.setAttribute('width', widthOne + 'px');
  svgOne.setAttribute('height', heightOne + 'px');
  svgOne.style.left = leftOne + 'px';
  svgOne.style.top = bottomOne + 'px';

}

function initSVGLinesLanding() {
  const triggerOne = document.querySelector('.section-1')
  const triggerTwo = document.querySelector('.section-2')
  const svgPathOne = document.querySelector('#svg-line-1')

  const tlOne = gsap.timeline();
  tlOne.fromTo(svgPathOne, {
    drawSVG: 0,
    ease: 'power2.out',
  },{
    drawSVG: "100%",
  }, "+=0");

  ScrollTrigger.create({
    trigger: triggerOne,
    endTrigger: triggerTwo,
    animation: tlOne,
    start: "55% center",
    end: "bottom -330%",
    scrub: true,
    //markers: true,
  });

  
}


function HorizontalScrollLanding() {
  if (media.lg) return;

  const scrollContainer = $qs('.horizontal-scroll');
  let sections = gsap.utils.toArray(".horizontal-scroll__section");
  let end = document.querySelector(".horizontal-scroll").offsetWidth + 1000;

  const scrollSection = gsap.timeline();
  scrollSection.to(sections, {
    opacity: 1,
    duration: 0.005,
    ease: 'none',
  }, '+=0')
  .to(sections, {
    xPercent: -100 * (sections.length - 1),
    ease: "none",
  }, '+=0')
  .to(sections, {
    opacity: 1,
    duration: 0.1,
    ease: 'none',
  }, '-=0.1');

  ScrollTrigger.create({
    trigger: ".horizontal-scroll",
    animation: scrollSection,
    pin: true,
    scrub: 1,
    snap: false,
    end: () => "+=" + end,
  });


}


function Landing() {
  positionSVGLinesLanding();
  initSVGLinesLanding();
  HorizontalScrollLanding();
}

export default Landing;