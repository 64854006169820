import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from "gsap/CustomEase";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { $qs, $qsa } from '../utils/QuerySelector';
import { media } from '../utils/MediaQueries';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(DrawSVGPlugin);



// Set width and height of svg line
function sizeSVGLines() {

  //if (media.lg) return;
  
  const svgOne = $qs('.svg-wrapper .svg-line');

  const main = $qs('.main');
  let heightOne = main.offsetHeight;
  // let widthOne = heightOne / 27.3;
  let widthOne = heightOne / 27.389;

  // Svg 1
  svgOne.setAttribute('width', widthOne + 'px');
  svgOne.setAttribute('height', heightOne + 'px');

}


// Position dots onto svg line
function positionDots() {

  //if (media.lg) return;
  
  const svgWrapper = $qs('.svg-wrapper');
  const admissionDots = $qs('.step__dot');

  let svgWrapperLeft = svgWrapper.offsetLeft;
  let svgWrapperWidth = svgWrapper.offsetWidth;

  let stepLeft = $('.step').offset();
  stepLeft = stepLeft.left;

  let dotPositionLeft = svgWrapperLeft + svgWrapperWidth - stepLeft - 47;

  $('.step__dot').each(function(i) {
    $(this).css('left', dotPositionLeft + 'px');
  });
  //admissionDots.style.left = dotPositionLeft + 'px';

}


function initAdmissionSvgLine() {
  if (media.lg) return;


  const triggerOne = document.querySelector('.svg-wrapper')
  const svgPathOne = document.querySelector('#svg-line-1')


  const tlOne = gsap.timeline();
  tlOne.fromTo(svgPathOne, {
    drawSVG: "0%",
    //ease: 'power1.out',
    ease: "none",
  },{
    drawSVG: "100%",
  }, "+=0");

  ScrollTrigger.create({
    trigger: triggerOne,
    animation: tlOne,
    start: "top 75%",
    //end: "155% 80%",
    end: "150% bottom",
    scrub: true,
    markers: true
  });
}


function initAdmissionSvgLineAlt() {
  if (media.lg) return;


  const triggerOne = document.querySelector('.svg-wrapper')
  const svg = document.querySelector('.svg-line')
  const linePath = svg.querySelector('path');

  const lineLength = linePath.getTotalLength();
  gsap.set(linePath, { attr: { "stroke-dasharray": lineLength, "stroke-dashoffset": lineLength }})
  const tl = gsap.timeline();
  tl.to(linePath, { 
    ease: 'none',
    duration: 1,
    attr: { "stroke-dashoffset": 0 }
  });


  ScrollTrigger.create({
    trigger: triggerOne,
    animation: tl,
    start: "top 75%",
    end: "bottom +220p%",
    onUpdate: self => { 
      prog = self.progress.toFixed(2);
      console.log(prog);
    },
    markers: true,
    scrub: true,
    //once: true
  });

}



function Admissions() {
  sizeSVGLines();
  positionDots();
  //initAdmissionSvgLine();
  //initAdmissionSvgLineAlt();
}

export default Admissions;