import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from "gsap/CustomEase";
import { SplitText } from "gsap/SplitText";
import { $qs, $qsa } from '../utils/QuerySelector';
import { media } from "../utils/MediaQueries";

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(SplitText);


function loadMenu() {
  const logo = $qs('.top-nav__logo');
  const menuButton = $qs('.js-menu-button');

  gsap.set(logo, {opacity: 0, y: "-30px"});
  gsap.set(menuButton, {opacity: 0, y: "-30px", x: "30px"});

  const menuAnimation = gsap.timeline();
  menuAnimation.to(logo, {
    opacity: 1,
    y: 0,
    duration: 0.3,
    ease: 'power2.out',
    delay: 0.6,
  }, '+=0')
  .to(menuButton, {
    opacity: 1,
    y: 0,
    x: 0,
    duration: 0.3,
    ease: 'power2.out',
  }, "+=0.2");
}




const body = $qs('body');

function line() {
    const lineElements = $qsa('[twk-animation="line"]');

    if (!lineElements) return;
    
    [...lineElements].forEach(lineElement => {
        const animation = gsap.from(lineElement, {
            height: '0px',
            duration: 1,
            ease: 'power2.out',
        });

        ScrollTrigger.create({
            trigger: lineElement,
            animation: animation,
            start: 'top 30%',
            markers: body.classList.contains('logged-in')
        });
    });

}

function text() {
    const textElements = $qsa('[twk-animation="text"]');

    if (!textElements) return;
    
    [...textElements].forEach(textElement => {

        const splitTitle = new SplitText(textElement, {
            type: 'chars,words',
            linesClass: 'line',
            charsClass: 'char'
        });

        const animation = gsap.from(splitTitle.chars, {
            x: '-10px',
            opacity: 0,
            stagger: .0575,
            duration: 1.5,
            ease: 'power2.out',
        });

        ScrollTrigger.create({
            trigger: textElement,
            animation: animation,
            start: 'top 90%',
            markers: body.classList.contains('logged-in')
        });
    });
}


function jigsawPuzzle() {
  const jigsaws = $qsa('[twk-animation="jigsaw"]');
  const jigsawWrapper = $qsa('.puzzle');
  const jigsawFirst = $qsa('[twk-animation="jigsaw-first"]');

  if (!jigsaws) return;
  
  [...jigsaws].forEach(jigsaw => {

    const animation = gsap.from(jigsaw, {
      opacity: 0,
      duration: 0.8,
      stagger: 0.4,
      ease: CustomEase.create("customEase", "0.215, 0.61, 0.355, 1")
    });

    ScrollTrigger.create({
        trigger: jigsawWrapper,
        fastScrollEnd: true,
        once: true,
        animation: animation,
        start: 'bottom 80%',
        markers: body.classList.contains('logged-in')
    });

  });
}

function OnScroll() {
    loadMenu();
    text();
    line();
    //jigsawPuzzle();
}

export default OnScroll;