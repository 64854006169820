import gsap from 'gsap';
import { $qs, $qsa } from '../utils/QuerySelector';
import { media } from '../utils/MediaQueries';



function setInitialCursorPosition(videoTeaser) {
    const cursor = $qs('.video-cursor', videoTeaser);
    
    cursor.style.transform = `translate(${videoTeaser.offsetWidth / 2}px, ${videoTeaser.offsetHeight / 1.5}px)`;
}

function handleMouseMove(e) {
    const cursor = $qs('.video-cursor', this);
    const body = $qs('body');
    const { pageX, pageY } = e;
    const x = pageX - this.getBoundingClientRect().left;
    const y = pageY - this.getBoundingClientRect().top;
    
    gsap.to(cursor, {
      x: x,
      y: y,
      duration: 0.5
    });

    // body.style.cursor = 'none';
}

function handleMouseLeave(e) {
    const cursor = $qs('.video-cursor', this);
    const body = $qs('body');
    const x = this.offsetWidth / 2;
    const y = this.offsetHeight / 1.5;

    gsap.to(cursor, {
        x: x,
        y: y,
        duration: 1,
        ease: 'power3.out'
    });

    // body.style.cursor = 'auto';
}

function VideoCursor() {
    const videoTeasers = $qsa('.js-video-cursor');

    if (!videoTeasers) return;

    videoTeasers.forEach(videoTeaser => {
        setInitialCursorPosition(videoTeaser);
        window.addEventListener('load', () => setInitialCursorPosition(videoTeaser));

        if (media.lg) return;
        
        videoTeaser.addEventListener('mousemove', handleMouseMove);
        videoTeaser.addEventListener('wheel', handleMouseMove);
        videoTeaser.addEventListener('mouseleave', handleMouseLeave);
    })
}

export default VideoCursor;