import gsap from "gsap";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { CustomEase } from "gsap/CustomEase";
import { SplitText } from "gsap/SplitText";
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin";
import { $qs, $qsa } from '../utils/QuerySelector';
import { media } from '../utils/MediaQueries';

gsap.registerPlugin(ScrollTrigger);
gsap.registerPlugin(CustomEase);
gsap.registerPlugin(SplitText);
gsap.registerPlugin(DrawSVGPlugin);


function HomeBannerAnimation() {
  const bannerTitle = $qs(".banner__title");
  const bannerIntro = $qs(".banner__content");
  const bannerScroll = $qs(".banner__scroll");
  const videoCursor = $qs(".video-cursor");

  gsap.set(bannerTitle, {opacity: 0, y: "70px"});
  gsap.set(bannerIntro, {opacity: 0, y: "70px"});
  gsap.set(videoCursor, {opacity: 0, y: "70px"});
  gsap.set(bannerScroll, {opacity: 0});

  const bannerAnimation = gsap.timeline();
  bannerAnimation.to(bannerTitle, {
    opacity: 1,
    y: 0,
    duration: 0.4,
    ease: 'power2.out',
    delay: 1.5,
  }, '+=0')
  .to(bannerIntro, {
    opacity: 1,
    y: 0,
    duration: 0.4,
    ease: 'power2.out',
  }, "+=0.2")
  .to(bannerScroll, {
    opacity: 1,
    y: 0,
    duration: 0.3,
    ease: 'power2.out',
  }, "+=0.2")
  .to(videoCursor, {
    opacity: 1,
    duration: 0.3,
    ease: 'power2.out',
  }, "+=0");
}

function scrollDownIcon() {
  //const scrollIcon = document.querySelector('#path-1');
  const scrollIcon = document.querySelector('#arrow-mask-inner');
  gsap.set(scrollIcon, {y: -5});

  const tl = gsap.timeline();

  tl.to(scrollIcon, {
    y: 35,
    ease: 'power2.in',
    duration: 1.8,
    repeat: -1,
    stagger: 0.25
  },3);
  /*
  tl.set(scrollIcon, {y:20});
  tl.to(scrollIcon, {
    y: 50,
    ease: 'power2.out',
    duration: 3,
    repeat: -1,
    stagger: 0.25
  }, "+=0.5");
  */

}


// Set width, height, position left and bottom of svg lines
function positionSVGLines() {

  if (media.lg) return;
  
  const svgOne = $qs('.section-1 .svg-line');
  const svgTwo = $qs('.section-2 .svg-line');
  const svgThree = $qs('.section-3 .svg-line');
  const svgShip = $qs('.svg-ship');
  const svgPhoenix = $qs('.svg-phoenix');
  const svgBook = $qs('.svg-book');
  const svgFlower = $qs('.svg-flower');

  const titleOne = $qs('.section-1 h3');
  const titleTwo = $qs('.section-2 h3');
  const titleThree = $qs('.section-3 h3');
  const titleFour = $qs('.section-4 h3');

  let leftOne = titleOne.offsetLeft + 220;
  let widthOne = titleTwo.offsetLeft - leftOne + window.innerWidth;
  let heightOne = widthOne / 3.387;
  let bottomOne = titleOne.offsetTop - titleOne.offsetHeight;

  let leftTwo = titleTwo.offsetLeft + 340;
  let widthTwo = titleThree.offsetLeft - 20 - leftTwo + window.innerWidth;
  let heightTwo = widthTwo / 4.35;
  let bottomTwo = titleTwo.offsetTop - titleTwo.offsetHeight - 70;

  let leftThree = titleThree.offsetLeft + 280;
  let widthThree = titleFour.offsetLeft - leftThree + 30 + window.innerWidth;
  let heightThree = widthThree / 3.188;
  let bottomThree = titleThree.offsetTop - titleThree.offsetHeight + 120;


  let shipLeft = ( titleOne.offsetLeft + 220 ) + ( 0.12 * widthOne );
  let shipBottom = bottomOne;

  let phoenixLeft = ( titleTwo.offsetLeft + 340 ) + ( 0.17 * widthTwo );
  let phoenixBottom = heightTwo * 0.52 ;

  let bookLeft = ( titleTwo.offsetLeft + 340 ) + ( 0.68 * widthTwo );
  let bookBottom = bottomTwo - 30;

  let flowerLeft = ( titleOne.offsetLeft + 280 ) + ( 0.52 * widthThree );
  let flowerBottom = bottomThree + ( heightThree / 2 ) - 40;


  // Svg 1
  svgOne.setAttribute('width', widthOne + 'px');
  svgOne.setAttribute('height', heightOne + 'px');
  svgOne.style.left = leftOne + 'px';
  svgOne.style.bottom = bottomOne + 'px';

  // Svg 2
  svgTwo.setAttribute('width', widthTwo + 'px');
  svgTwo.setAttribute('height', heightTwo + 'px');
  svgTwo.style.left = leftTwo + 'px';
  svgTwo.style.bottom = bottomTwo + 'px';

  // Svg 3
  svgThree.setAttribute('width', widthThree + 'px');
  svgThree.setAttribute('height', heightThree + 'px');
  svgThree.style.left = leftThree + 'px';
  svgThree.style.bottom = bottomThree + 'px';


  // Icons
  svgShip.style.left = shipLeft + 'px';
  svgShip.style.bottom = shipBottom + 'px';

  svgPhoenix.style.left = phoenixLeft + 'px';
  svgPhoenix.style.bottom = phoenixBottom + 'px';

  svgBook.style.left = bookLeft + 'px';
  svgBook.style.bottom = bookBottom + 'px';

  svgFlower.style.left = flowerLeft + 'px';
  svgFlower.style.bottom = flowerBottom + 'px';

}

function initSVGLines() {
  if (media.lg) return;


  const triggerOne = document.querySelector('.section-1')
  const svgPathOne = document.querySelector('#svg-line-1')
  const svgShip = document.querySelector('.svg-ship')
  gsap.set(svgShip, {opacity: 0});

  //const tl = gsap.timeline().fromTo(svgPathOne, {drawSVG: 0}, {drawSVG: "100%"});
  //const tlShip = gsap.timeline().to(svgShip, {opacity: 1, duration: 0.2});

  const tlOne = gsap.timeline();
  tlOne.fromTo(svgPathOne, {
    drawSVG: 0,
    ease: 'power2.out',
  },{
    drawSVG: "100%",
  }, "+=0")
  .to(svgShip, {
    opacity: 1,
    duration: 0.2,
    ease: 'power2.out',
  }, '-=0.52');

  ScrollTrigger.create({
    trigger: triggerOne,
    animation: tlOne,
    start: "15% center",
    end: "200% bottom",
    scrub: true,
  });
  /*
  ScrollTrigger.create({
    trigger: triggerOne,
    animation: tlShip,
    start: "20% center",
    //end: "130% bottom",
    scrub: true,
  });
*/

  const triggerTwo = document.querySelector('.section-2')
  const svgPathTwo = document.querySelector('#svg-line-2')
  const svgPhoenix = document.querySelector('.svg-phoenix')
  const svgBook = document.querySelector('.svg-book')
  gsap.set(svgBook, {opacity: 0});
  gsap.set(svgPhoenix, {opacity: 0});

  const tlTwo = gsap.timeline();
  tlTwo.fromTo(svgPathTwo, {
    drawSVG: 0,
    ease: 'power2.out',
  }, {
    drawSVG: "100%"
  }, "+=0")
  .to(svgPhoenix, {
    opacity: 1,
    duration: 0.2,
    ease: 'power2.out',
  }, '-=0.51')
  .to(svgBook, {
    opacity: 1,
    duration: 0.2,
    ease: 'power2.out',
  }, '-=0.35');

  ScrollTrigger.create({
    trigger: triggerTwo,
    animation: tlTwo,
    start: "170% center",
    end: "350% bottom",
    scrub: true,
  });

  
  const triggerThree = document.querySelector('.section-3')
  const svgPathThree = document.querySelector('#svg-line-3')
  const svgFlower = document.querySelector('.svg-flower')
  gsap.set(svgFlower, {opacity: 0});

  const tlThree = gsap.timeline();
  tlThree.fromTo(svgPathThree, {
    drawSVG: 0,
    ease: 'power2.out',
  }, {
    drawSVG: "100%"
  }, "+=0")
  .to(svgFlower, {
    opacity: 1,
    duration: 0.2,
    ease: 'power2.out',
  }, '-=0.45');

  ScrollTrigger.create({
    trigger: triggerThree,
    animation: tlThree,
    start: "290% center",
    end: "430% bottom",
    //markers: true,
    scrub: true,
  });
  
}


function HorizontalScroll() {
  if (media.lg) return;

  const scrollContainer = $qs('.horizontal-scroll');
  let sections = gsap.utils.toArray(".horizontal-scroll__section");
  let end = document.querySelector(".horizontal-scroll").offsetWidth + 2000;

  const scrollSection = gsap.timeline();
  scrollSection.to(sections, {
    opacity: 1,
    duration: 0.005,
    ease: 'none',
  }, '+=0')
  .to(sections, {
    xPercent: -100 * (sections.length - 1),
    ease: "none",
  }, '+=0')
  .to(sections, {
    opacity: 1,
    duration: 0.1,
    ease: 'none',
  }, '-=0.1');

  ScrollTrigger.create({
    trigger: ".horizontal-scroll",
    animation: scrollSection,
    pin: true,
    scrub: 1,
    snap: false,
    end: () => "+=" + end,
  });


  /*
  gsap.to(sections, {
    xPercent: -100 * (sections.length - 1),
    //x: () => -(scrollContainer.scrollWidth - document.documentElement.clientWidth) + "px",
    ease: "none",
    scrollTrigger: {
      trigger: ".horizontal-scroll",
      pin: true,
      scrub: 1,
      snap: false,
      end: () => "+=" + end,
      //end: () => "+=" + document.querySelector(".horizontal-scroll").offsetWidth 
      //end: () => "+=" + (scrollContainer.offsetWidth - innerWidth)
    }
  });
  */


  let introTitleBg = gsap.utils.toArray(".horizontal-scroll-intro__background");
  gsap.set(introTitleBg, { x: "-100%", opacity: 0 });

  ScrollTrigger.create({
    trigger: ".horizontal-scroll",
    start: "top 70%",
    end: "20% bottom",
    onEnter: () => gsap.to(introTitleBg, {
      x: 0,
      opacity: 1,
      duration: 1,
      delay: 0.3,
      ease: 'power3.out',
      overwrite: 'auto'
    }),
    /*
    onLeaveBack: () => gsap.to(introTitleBg, {
      x: "-100%",
      opacity: 0,
      duration: 1,
      ease: 'power3.out',
      overwrite: 'auto'
    })
    */
  });


  let introTitle = gsap.utils.toArray(".horizontal-scroll-intro h2");

  const splitTitle = new SplitText(introTitle, {
    type: 'words',
    //linesClass: 'line',
    wordsClass: 'word',
    //charsClass: 'char'
  });
  const words = splitTitle.words; // array of all words from the title
  
  gsap.set(words, { opacity:0, y: "-30px" });

  ScrollTrigger.create({
    trigger: ".horizontal-scroll",
    start: "top 70%",
    end: "20% bottom",
    onEnter: () => gsap.to(words, {
      duration: 1,
      delay: 0.8,
      opacity: 1,
      y: 0,
      ease: 'power3.out',
      overwrite: 'auto',
      stagger: 0.05
    }),
    /*
    onLeaveBack: () => gsap.to(introTitleBg, {
      y: "-30px",
      opacity: 0,
      duration: 1,
      ease: 'power3.out',
      overwrite: 'auto',
      stagger: 0.05
    })
    */
  });


}


function Home() {
  HomeBannerAnimation();
  scrollDownIcon();
  HorizontalScroll();
  positionSVGLines();
  initSVGLines();
}

export default Home;