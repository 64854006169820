/* 
* A class to simplify JS media queries.
* using a size will return true if the screen size is below that size, and false if it is above
*/

class Media {
  constructor() {
      this.sizes = {
          xs: 576,
          sm: 768,
          md: 992,
          lg: 1200
      };

      // init
      this.setSizes();
  }

  setSizes() {
      Object.entries(this.sizes).forEach(([key, value]) => {
          this[key] = window.matchMedia(`(max-width: ${value - 1}px)`).matches;
      });
  }

  max(size) {
      return size ? window.matchMedia(`(max-width: ${size}px)`).matches : console.error('no custom size supplied');
  }

  min(size) {
      return size ? window.matchMedia(`(min-width: ${size}px)`).matches : console.error('no custom size supplied');
  }
}

export const media = new Media;